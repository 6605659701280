import React, { useState } from 'react';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, TextField, Button, Select, MenuItem, ButtonProps, IconButton, Modal, Typography, Checkbox, FormControlLabel, Alert, AlertTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { z } from 'zod';

interface UserBand {
  from: number;
  to: number;
  standard: {
    priceInCents: number;
    users: number;
  },
  advanced: {
    priceInCents: number;
    users: number;
  }
}

type UpdateBandModalButonProps = {
  bands: UserBand[];
  setBands: (bands: UserBand[]) => void;
  modalContents: (handleClose: () => void) => React.ReactNode;
}
const UpdateBandModalButton: React.FC<UpdateBandModalButonProps & Omit<ButtonProps, 'onclick'>> = ({ bands, setBands, modalContents, children, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return <>
    <Button {...buttonProps} onClick={handleOpen}>{children}</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {modalContents(handleClose)}
      </Box>
    </Modal>
  </>
}

type LicensingStrategyProps = {
  heading?: React.ReactNode;
}
const LicensingStrategy: React.FC<LicensingStrategyProps> = (props) => {
  const [includeInModel, setIncludeInModel] = useState(false);

  const [addonKey, setAddonKey] = useState<string>('com.onresolve.jira.groovy.groovyrunner');
  const [dedupePricing, setDedupePricing] = useState<boolean>(true);
  const [multiplier, setMultiplier] = useState<number>(1);

  const [priceBy, setPriceBy] = useState<'users' | 'agents' | 'usage'>('users');

  const [bands, setBands] = useState<UserBand[]>([
    // { from: 0, to: 10, standard: { priceInCents: 0, users: 0 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 11, to: 100, standard: { priceInCents: 238, users: 1_000_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 101, to: 250, standard: { priceInCents: 143, users: 1_500_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 251, to: 1000, standard: { priceInCents: 48, users: 2_000_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 1001, to: 2500, standard: { priceInCents: 43, users: 1_000_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 2501, to: 5000, standard: { priceInCents: 38, users: 40_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 5001, to: 7500, standard: { priceInCents: 33, users: 20_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 7501, to: 10000, standard: { priceInCents: 29, users: 11_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 10001, to: 15000, standard: { priceInCents: 24, users: 13_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 15001, to: 20000, standard: { priceInCents: 19, users: 18_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 20001, to: 25000, standard: { priceInCents: 9, users: 50_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 25001, to: 30000, standard: { priceInCents: 4, users: 40_000 }, advanced: { priceInCents: 0, users: 0 } },
    // { from: 30001, to: 50000, standard: { priceInCents: 2, users: 32_000 }, advanced: { priceInCents: 0, users: 0 } },
    // Add initial bands as needed
  ]);

  const addBand = () => {
    setBands([...bands, { from: 0, to: 0, standard: { priceInCents: 0, users: 0 }, advanced: { priceInCents: 0, users: 0 } }]);
  };

  const removeBand = (index: number) => {
    const newBands = bands.filter((_, i) => i !== index);
    setBands(newBands);
  };

  const formatCurrency = (valueInCents: number) => {
    const valueInDollars = valueInCents / 100;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(valueInDollars);
  };

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const totalStandardUsers = bands.reduce((sum, band) => sum + band.standard.users, 0);
  const totalAdvancedUsers = bands.reduce((sum, band) => sum + band.advanced.users, 0);
  const totalRevenue = bands.reduce((sum, band) => sum + (band.standard.users * band.standard.priceInCents) + (band.advanced.users * band.advanced.priceInCents), 0);

  return (
    <Box marginBottom={5}>
      {props.heading}
      {includeInModel && (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  Price by
                  <Select value={priceBy} onChange={(e) => setPriceBy(e.target.value as 'users' | 'agents' | 'usage')} sx={{ marginLeft: 1 }}>
                    <MenuItem value={'users'}>Users</MenuItem>
                    <MenuItem value={'agents'}>Agents</MenuItem>
                    <MenuItem value={'usage'}>Usage Metric</MenuItem>
                  </Select>
                </TableCell>
                <TableCell colSpan={2} align='center'>
                  <UpdateBandModalButton
                    bands={bands}
                    setBands={setBands}
                    modalContents={(handleClose) => <>
                      <Typography variant='h6'>Import from Atlassian Marketplace</Typography>
                      <br />
                      <Alert severity='info'>
                        <AlertTitle>For Example</AlertTitle>
                        <Select onChange={(e) => setAddonKey(e.target.value as string)} fullWidth>
                          <MenuItem value={'com.onresolve.jira.groovy.groovyrunner'}>Scriptrunner for Jira</MenuItem>
                          <MenuItem value={'com.fca.jira.plugins.workflowToolbox.workflow-toolbox'}>Jira Workflow Toolbox</MenuItem>
                          <MenuItem value={'com.comalatech.workflow'}>Comala Document Management</MenuItem>
                          <MenuItem value={'com.infosysta.jfd.jira-for-desktop'}>Desktop Connector for Jira (Windows and Mac)</MenuItem>
                          <MenuItem value={'so.released.app'}>Released - AI Release Notes and Roadmap Automation for Jira</MenuItem>
                        </Select>
                      </Alert>
                      <TextField
                        label='Addon Key'
                        value={addonKey}
                        onChange={(e) => setAddonKey(e.target.value)}
                        fullWidth
                        variant='outlined'
                        sx={{ mt: 2, mb: 3 }}
                      />
                      <FormControlLabel control={
                        <Checkbox
                          checked={dedupePricing}
                          onChange={(e) => setDedupePricing(e.target.checked)}
                        />
                      } label="Collapse duplicate pricing bands" />
                      <br />
                      <br />
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                          // Get the JSON pricing from the Atlassian Marketplace
                          const result = await fetch(`https://marketplace.atlassian.com/rest/2/addons/${addonKey}/pricing/cloud/live`, {
                            method: 'GET',
                            headers: {
                              'Accept': 'application/json'
                            }
                          })
                          const json = await result.json()

                          // Parse the response into the expected format
                          const zMarketplacePricing = z.object({
                            perUnitItems: z.array(z.object({
                              amount: z.number(),
                              unitCount: z.number(),
                            }))
                          })
                          const pricing = zMarketplacePricing.parse(json)

                          // Convert the price into cents
                          pricing.perUnitItems.forEach(item => {
                            item.amount = Math.round(item.amount * 100)
                          })

                          // Ensure the pricing is sorted by unitCount, but with -1 at the end
                          pricing.perUnitItems.sort((a, b) => a.unitCount === -1 ? 1 : b.unitCount === -1 ? -1 : a.unitCount - b.unitCount)

                          // Collapse duplicate bands by removing the former bands that match the price of the next band
                          const dedupedPricing = dedupePricing
                            ? pricing.perUnitItems.filter((item, index) => {
                              return index === 0 || item.amount !== pricing.perUnitItems[index - 1].amount
                            })
                            : pricing.perUnitItems

                          // Build the bands from the pricing
                          const newBands = dedupedPricing.map((item, index) => {
                            return {
                              from: index === 0 ? 0 : pricing.perUnitItems[index - 1].unitCount + 1,
                              to: item.unitCount,
                              standard: { priceInCents: item.amount, users: 0 },
                              advanced: { priceInCents: 0, users: 0 }
                            }
                          })

                          // Update the bands and return
                          setBands(newBands)
                          handleClose();
                        }}
                      >
                        Import
                      </Button>
                    </>}
                    variant='text'
                  >Import</UpdateBandModalButton> Standard Packaging
                </TableCell>
                <TableCell colSpan={2} align='center'>
                  <UpdateBandModalButton
                    bands={bands}
                    setBands={setBands}
                    modalContents={(handleClose) => <>
                      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Generate Advanced Packaging
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        Replace the current advanced packaging pricing with:
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          const newBands = [...bands];
                          newBands.forEach(band => {
                            band.advanced.priceInCents = 0;
                          });
                          setBands(newBands);
                          handleClose()
                        }}
                        sx={{ mb: 2 }}
                      >
                        Zeros
                      </Button>

                      <br />
                      or
                      <br />
                      <br />

                      <TextField
                        label="Multiplier"
                        type="number"
                        value={multiplier}
                        onChange={(e) => setMultiplier(parseInt(e.target.value))}
                        sx={{ width: 100, mr: 2 }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => {
                          const newBands = [...bands];
                          newBands.forEach(band => {
                            band.advanced.priceInCents = band.standard.priceInCents * multiplier;
                          });
                          setBands(newBands);
                          handleClose()
                        }}
                        sx={{ mt: 2 }}
                      >
                        Standard x{multiplier}
                      </Button>
                    </>}
                    variant='text'
                  >Generate</UpdateBandModalButton> Advanced Packaging
                </TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>
                  Standard<br />
                  Monthly Price<br />
                  (in cents)
                </TableCell>
                <TableCell>
                  Total # of<br />
                  Standard<br />
                  Active Users
                </TableCell>
                <TableCell>
                  Advanced<br />
                  Monthly Price<br />
                  (in cents)<br />
                </TableCell>
                <TableCell>
                  Total # of<br />
                  Advanced<br />
                  Active Users
                </TableCell>
                <TableCell>Total Revenue</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bands.map((band, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.from}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].from = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.to}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].to = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.standard.priceInCents}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].standard.priceInCents = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.standard.users}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].standard.users = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.advanced.priceInCents}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].advanced.priceInCents = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={band.advanced.users}
                      onChange={(e) => {
                        const newBands = [...bands];
                        newBands[index].advanced.users = parseInt(e.target.value);
                        setBands(newBands);
                      }}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {formatCurrency((band.standard.users * band.standard.priceInCents) + (band.advanced.users * band.advanced.priceInCents))}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="error" onClick={() => removeBand(index)}>
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'right' }}>Total</TableCell>
                <TableCell>{formatNumber(totalStandardUsers)}</TableCell>
                <TableCell></TableCell>
                <TableCell>{formatNumber(totalAdvancedUsers)}</TableCell>
                <TableCell>{formatCurrency(totalRevenue)}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={addBand} startIcon={<AddIcon />}>
                    Band
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
      {!includeInModel && (
        <Button
          variant='contained'
          onClick={() => setIncludeInModel(true)}
          sx={{ marginTop: 2 }}
        >
          Add Strategy to Model
        </Button>
      )}
    </Box>
  );
}

export default LicensingStrategy;
